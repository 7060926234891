import "../styles/Governance.scss";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
// 이미지
import searchIcon from "../assets/images/searchIcon.png";
import checkImg from "../assets/images/check.png";
import linkIcon from "../assets/images/open_in_new.svg";

import DeleteModal from "../components/DeleteModal";
import TokenType from "../components/TokenType";
import GovernanceCancelModal from "../components/GovernanceCancelModal";

function Governance() {
  const serverApi = process.env.REACT_APP_AWS_API;
  // 토큰 정보
  const token = Cookies.get("token");
  const [cancelShowModal, setCancelShowModal] = useState();
  const [network, setNetwork] = useState("ethereum"); // 가상으로 network 상태 정의
  const [searchKeyword, setSearchKeyword] = useState(""); // 검색어 상태
  const [activePage, setActivePage] = useState(1); // 현재 페이지 상태
  const [totalPages, setTotalPages] = useState(0); // 전체 페이지 수
  const pagesPerBlock = 5; // 한 번에 보여질 페이지 수
  const [pageNumbers, setPageNumbers] = useState([]); // 가상의 페이지 번호
  const [userList, setUserList] = useState([]); // 사용자 목록 상태
  const [governanceId, setGovernanceId] = useState(0); // 거버넌스 ID 상태
  const [isActive, setIsActive] = useState(false); // active 상태 관리
  const [selectedText, setSelectedText] = useState(""); // 제목 텍스트 상태 관리
  console.log("selectedText", selectedText);
  console.log("pageNumbers", pageNumbers);
  console.log("totalPages", totalPages);
  // 취소 버튼 클릭시
  const clickCancelButton = (id) => {
    setCancelShowModal(true);
    setGovernanceId(id);
  };
  // 거버넌스 목록 가져오기
  const getGovernanceList = async () => {
    try {
      // 기본 URL을 설정합니다.
      let url = `${serverApi}/api/cms/governance/list?page=${activePage}`;

      // selectedText가 존재하면 status 파라미터를 추가합니다.
      if (selectedText) {
        url += `&status=${selectedText}`;
      }

      const res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`, // 토큰을 사용한 인증 헤더
        },
      });

      console.log("거버넌스 목록 가져오기 성공", res);
      setUserList(res.data.data_list);
      setTotalPages(Math.ceil(res.data.total_cnt / 20)); // 토탈 페이지 다시 계산
    } catch (error) {
      console.log("거버넌스 목록 가져오기 실패", error.response);
    }
  };

  // 페이지 번호 변경 시 페이지 번호 배열 업데이트
  useEffect(() => {
    // 페이지 번호 계산
    const startPage =
      Math.floor((activePage - 1) / pagesPerBlock) * pagesPerBlock + 1;

    // 페이지 번호 배열 생성
    const newPageNumbers = Array.from(
      { length: pagesPerBlock },
      (_, i) => startPage + i
    ).filter((page) => page <= totalPages);

    setPageNumbers(newPageNumbers);
  }, [activePage, totalPages]);

  // 페이지 변경 시 거버넌스 목록 가져오기
  useEffect(() => {
    getGovernanceList();
  }, [activePage, selectedText]);

  // 검색 기능 구현
  const performSearch = (keyword) => {
    console.log("Searching for:", keyword);
    // 검색 기능 로직 추가
  };

  const onSearchButtonClick = () => {
    performSearch(searchKeyword);
  };

  // 페이지 변경 핸들러
  const handlePageClick = (pageNumber) => {
    setActivePage(pageNumber);
    console.log("Page changed to:", pageNumber);
  };

  const toggleActiveClass = () => {
    setIsActive(!isActive); // active 클래스 토글
  };

  const handleItemClick = (text) => {
    setSelectedText(text); // 선택한 li 텍스트로 제목 변경
    setIsActive(false); // active 클래스 제거
  };

  return (
    <>
      <div className="main">
        <div className="main--inner">
          <div className="header--box">
            <div className="main--title">
              거버넌스 관리
              <TokenType tokenType={network} />
            </div>
            {/* 거버넌스 상태 셀렉트박스 */}
            <div className={`select--box ${isActive ? "active" : ""}`}>
              <p className="select--box__title" onClick={toggleActiveClass}>
                {selectedText || "All"}
              </p>
              <ul>
                <li onClick={() => handleItemClick(null)}>All</li>
                <li onClick={() => handleItemClick("In Progress")}>
                  In Progress
                </li>
                <li onClick={() => handleItemClick("END")}>END</li>
                <li onClick={() => handleItemClick("Proposal Spamming")}>
                  Proposal Spamming
                </li>
                <li onClick={() => handleItemClick("Duplicating Proposals")}>
                  Duplicating Proposals
                </li>
                <li onClick={() => handleItemClick("Vote Manipulation")}>
                  Vote Manipulation
                </li>
                <li
                  onClick={() =>
                    handleItemClick("Blocking Proposal Implementation")
                  }
                >
                  Blocking Proposal Implementation
                </li>
              </ul>
            </div>
          </div>
          {/* header--box --END--*/}
          <div className="tournament--list--box">
            <table>
              <thead>
                <tr>
                  <th>투표 시작일</th>
                  <th>투표 마감일</th>
                  <th>거버넌스 제목</th>
                  <th>지갑 주소</th>
                  <th>거버넌스 상태</th>
                  <th>Cancel</th>
                  <th>링크</th>
                </tr>
              </thead>
              <tbody>
                {userList.length > 0 &&
                  userList?.map((userData, index) => (
                    <tr key={userData.id}>
                      <td>
                        {new Date(`${userData?.start_date}+09:00`)
                          .toUTCString()
                          .replace("GMT", "UTC+0")}
                      </td>
                      <td>
                        {new Date(`${userData?.end_date}+09:00`)
                          .toUTCString()
                          .replace("GMT", "UTC+0")}
                      </td>
                      <td>{userData?.title}</td>
                      <td>{userData?.wallet_address}</td>
                      <td>{userData?.status}</td>
                      <td>
                        <button
                          className={`cancel--btn ${
                            userData?.status !== "In Progress" ? "active" : ""
                          }`}
                          // onClick={setCancelShowModal}
                          onClick={() => clickCancelButton(userData?.id)}
                          disabled={userData?.status !== "In Progress"}
                        >
                          Cancel
                        </button>
                      </td>
                      <td>
                        <Link
                          to={`https://earn.miracleplay.gg/governance/detail/${userData?.id}`}
                          target="_blank"
                          className="link--btn"
                        >
                          Link
                          {/* <img src={linkIcon} alt="linkIcon" /> */}
                        </Link>
                      </td>
                    </tr>
                  ))}
                {userList.length === 0 && (
                  <tr>
                    <td colSpan="7">데이터가 없습니다.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <ul className="pagination">
            <li className="page-item prev2" onClick={() => handlePageClick(1)}>
              처음
            </li>
            <li
              className={`page-item prev ${activePage === 1 ? "disabled" : ""}`}
              onClick={() => handlePageClick(Math.max(activePage - 1, 1))}
            >
              이전
            </li>
            {pageNumbers.map((number) => (
              <li
                key={number}
                className={`page-item ${activePage === number ? "active" : ""}`}
                onClick={() => handlePageClick(number)}
              >
                {number}
              </li>
            ))}
            <li
              className="page-item next"
              onClick={() =>
                handlePageClick(Math.min(activePage + 1, totalPages))
              }
            >
              다음
            </li>
            <li
              className="page-item next2"
              onClick={() => handlePageClick(totalPages)}
            >
              끝
            </li>
          </ul>
        </div>
      </div>
      {cancelShowModal && (
        <GovernanceCancelModal
          governanceId={governanceId}
          setCancelShowModal={setCancelShowModal}
          getGovernanceList={getGovernanceList}
        />
      )}
    </>
  );
}

export default Governance;
