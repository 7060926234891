import React from "react";
import axios from "axios";
import "./GovernanceCancelModalLast.scss";
import Cookies from "js-cookie";
const GovernanceCancelModalLast = ({
  setCancelShowModal,
  cancelStatus,
  governanceId,
  getGovernanceList,
}) => {
  const serverApi = process.env.REACT_APP_AWS_API;
  const token = Cookies.get("token");

  // 서버에 취소 요청 보내기
  const sendCancelRequest = async () => {
    try {
      const res = await axios.post(
        `${serverApi}/api/cms/governance/${governanceId}/cancel?status=${cancelStatus}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("취소 요청 성공", res);
      alert("취소 완료 되었습니다.");
      getGovernanceList();
      setCancelShowModal(false);
    } catch (error) {
      console.log("취소 요청 실패", error.response);
      alert("취소 요청 실패", error.response);
    }
  };
  return (
    <>
      <div className="Governance-delete-modal__box">
        <div className="delete-modal__title">
          {cancelStatus}
          <br /> 처리 하시겠습니까?
        </div>
        <div className="delete-modal__btn-box">
          <button
            className="delete-modal__cancel-btn"
            onClick={() => setCancelShowModal(false)}
          >
            아니오
          </button>
          <button
            className="delete-modal__confirm-btn"
            onClick={sendCancelRequest}
          >
            네
          </button>
        </div>
      </div>
      <div
        className="delete-modal__background"
        onClick={() => setCancelShowModal(false)}
      ></div>
    </>
  );
};

export default GovernanceCancelModalLast;
