import React, { useState } from "react";
import "./GovernanceCancelModal.scss";
import GovernanceCancelModalLast from "./GovernanceCancelModalLast";

const GovernanceCancelModal = ({
  setCancelShowModal,
  governanceId,
  getGovernanceList,
}) => {
  // 체크된 상태를 관리하는 state
  const [cancelStatus, setCancelStatus] = useState("");
  const [cancelShowModalLast, setCancelShowModalLast] = useState(false);
  console.log("cancelStatus", cancelStatus);
  // 확인 버튼 클릭 시 다음 모달로 이동
  const handleConfirmClick = () => {
    setCancelShowModalLast(true);
  };

  // 체크박스 상태 변경 함수
  const handleCheckboxChange = (option) => {
    setCancelStatus(option); // 선택된 텍스트로 상태 변경
  };

  return (
    <>
      {!cancelShowModalLast && (
        <>
          <div className="Governance-cancel-modal__box">
            <div className="delete-modal__title">
              Cancel 종류를 선택해주세요
            </div>
            <div className="Governance-delete-modal__check-list">
              <ul className="checklist">
                <li className="checklist-item">
                  <label>
                    <input
                      type="checkbox"
                      checked={cancelStatus === "Proposal Spamming"}
                      onChange={() => handleCheckboxChange("Proposal Spamming")}
                    />
                    <span className="custom-checkbox"></span>
                    Proposal Spamming
                  </label>
                </li>
                <li className="checklist-item">
                  <label>
                    <input
                      type="checkbox"
                      checked={cancelStatus === "Duplicating Proposals"}
                      onChange={() =>
                        handleCheckboxChange("Duplicating Proposals")
                      }
                    />
                    <span className="custom-checkbox"></span>
                    Duplicating Proposals
                  </label>
                </li>
                <li className="checklist-item">
                  <label>
                    <input
                      type="checkbox"
                      checked={cancelStatus === "Vote Manipulation"}
                      onChange={() => handleCheckboxChange("Vote Manipulation")}
                    />
                    <span className="custom-checkbox"></span>
                    Vote Manipulation
                  </label>
                </li>
                <li className="checklist-item">
                  <label>
                    <input
                      type="checkbox"
                      checked={
                        cancelStatus === "Blocking Proposal Implementation"
                      }
                      onChange={() =>
                        handleCheckboxChange("Blocking Proposal Implementation")
                      }
                    />
                    <span className="custom-checkbox"></span>
                    Blocking Proposal Implementation
                  </label>
                </li>
              </ul>
            </div>
            <div className="delete-modal__btn-box">
              <button
                className="delete-modal__confirm-btn"
                onClick={handleConfirmClick}
              >
                확인
              </button>
            </div>
          </div>
          <div
            className="delete-modal__background"
            onClick={() => setCancelShowModal(false)}
          ></div>
        </>
      )}
      {cancelShowModalLast && (
        <GovernanceCancelModalLast
          governanceId={governanceId}
          getGovernanceList={getGovernanceList}
          cancelStatus={cancelStatus}
          setCancelShowModal={setCancelShowModal}
        />
      )}
    </>
  );
};

export default GovernanceCancelModal;
